import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import './Home.css';
import image1 from '../assets/Connected.png';
import image2 from '../assets/Raid.png';
import image3 from '../assets/AutoFarm.png';

function Home() {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/subscriptions');
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const imagesData = [
    { img: image1, text: 'Enjoy customizable settings for each Byte including AutoFeed, AutoIce, AutoCraft, AutoRecycle, and more!', reverse: false },
    { img: image2, text: "Enjoy peace of mind with our advanced raid detection. We support team notifications so you can all stay in the loop while using the ByteHold service.", reverse: true },
    { img: image3, text: 'Enjoy the ease of farming with our advanced AutoFarm feature! This allows you to multi-task whilst your alt safely farms any sort of food.', reverse: false },
  ];

  return (
    <div className="home">
      {/* Hero Section */}
      <section className="hero">
        <h1 className="hero-title">Welcome to ByteHold</h1>
        <p className="hero-subtitle">The Ultimate AFK Solution for Starve.io</p>
        <button className="cta-button" onClick={handleGetStarted}>
          Get Started
        </button>
      </section>

      {/* Features Section */}
      <section className="features">
        <h2 className="section-title">Why Choose ByteHold?</h2>
        <div className="features-list">
          <div className="feature-item">
            <FaCheck className="feature-icon" />
            Reliable AFK Service
          </div>
          <div className="feature-item">
            <FaCheck className="feature-icon" />
            Secure Token Management
          </div>
          <div className="feature-item">
            <FaCheck className="feature-icon" />
            24/7 Uptime
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials">
        <h2 className="section-title">What Our Users Say</h2>
        <div className="testimonial-list">
          <div className="testimonial">
            <p>"ByteHold's user experience makes the difference between an average and exceptional product."</p>
            <div className="stars">★★★★★</div>
            <p>- Fire</p>
          </div>
          <div className="testimonial">
            <p>"Reliable and trustworthy are just two of the qualities that makes ByteHold more than worth the price."</p>
            <div className="stars">★★★★★</div>
            <p>- Anonymous</p>
          </div>
          <div className="testimonial">
            <p>"Classy and innovate, I will always use ByteHold for token mangement."</p>
            <div className="stars">★★★★★</div>
            <p>- Pet</p>
          </div>
        </div>
      </section>

      {/* Images Section */}
      <section className="images-section">
        {imagesData.map((data, index) => (
          <div
            key={index}
            className={`image-text-row ${data.reverse ? 'reverse' : ''}`}
          >
            <img src={data.img} alt={`Feature ${index + 1}`} className="image" />
            <p className="image-text">{data.text}</p>
          </div>
        ))}
      </section>

     {/* Footer */}
     <footer className="footer">
        <p>&copy; 2024 ByteHold. All rights reserved.</p>
        <div className="footer-links">
          <span onClick={() => handleNavigate('/terms-of-service')}>Terms of Service</span><br></br>
          <span onClick={() => handleNavigate('/privacy-policy')}>Privacy Policy</span><br></br>
          <span onClick={() => handleNavigate('/refund-policy')}>Refund Policy</span><br></br>
        </div>
      </footer>
    </div>
  );
}

export default Home;