import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import "./Checkout.css";
import './Policies.css';

// Initialize Stripe outside the component
const stripePromise = axios
  .post(`${process.env.REACT_APP_BACKEND_URL}/configuration`)
  .then((response) => loadStripe(response.data.publishableKey))
  .catch((err) => {
    console.error("Failed to initialize Stripe:", err);
    throw err;
  });

function Checkout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { bytes } = location.state || {};

  useEffect(() => {
    const createCheckoutSession = async () => {
      try {
        // Wait for Stripe to load
        const stripe = await stripePromise;

        if (!stripe) {
          throw new Error("Stripe failed to initialize.");
        }

        const referralId = localStorage.getItem("referralId");

        // Create checkout session
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/checkout`,
          { plan: bytes, referralId }
        );

        const { sessionId } = response.data;

        // Redirect to Stripe Checkout
        await stripe.redirectToCheckout({ sessionId });
      } catch (error) {
        console.error("Error creating checkout session:", error);
        const redirect = error?.response?.data?.redirect;
        if (redirect) {
          navigate(redirect);
          return;
        }
      }
    };

    if (bytes) {
      createCheckoutSession();
    } else {
      navigate("/"); // Redirect home if `bytes` is missing
    }
  }, [bytes, navigate]);
};

export default Checkout;