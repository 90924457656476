import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import "./Decline.css";

function Decline() {
    const { error } = useParams();
    const navigate = useNavigate();

    const getErrorMessage = (errorCode) => {
        switch (errorCode) {
            case 'checkout_aborted':
                return "It seems like you canceled the checkout process. If this was unintentional, please try again.";
            case 'payment_failed':
                return "We encountered an issue while processing your payment. Please check your payment details and try again.";
            default:
                return error;
        };
    };

    const handleGoHome = () => {
        navigate('/'); // Redirect to the home page
      };

    return (
        <div className="cancel-container">
        <div className="error-box">
          <h1>Payment Process Interrupted</h1>
          <h2>{getErrorMessage(error)}</h2>
          <button className="go-home-btn" onClick={handleGoHome}>Go Back to Home</button>
        </div>
      </div>
    );
}

export default Decline;
