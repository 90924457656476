import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "./Loading";

function Session() {
    const { session, plan, referralId } = useParams();
    const navigate = useNavigate();
    var isProcessingPayment = true;

    useEffect(() => {
        const fetchSessionData = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/retrieve-session`, { session, plan, referralId });
                isProcessingPayment = false;

                navigate('/success', { state: { activationKey: response.data.activationKey } });
            } catch (error) {
                navigate(error?.response?.data?.redirect);
                console.error("Error fetching session data:", error);
            }
        };

        fetchSessionData();
    }, [session, plan, referralId, navigate, isProcessingPayment]);

    return (
        <div className="checkout-container">
            {isProcessingPayment && <Loading overlay />} {/* Loading animation */}
        </div>
    )
}

export default Session;
