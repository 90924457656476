import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function Referrals() {
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id === 'otterly') {
            navigate('/checkout', { state: { bytes: 100 } });
            return;
        };

        localStorage.setItem('referralId', id);
        navigate('/');
    }, [id, navigate]);

    return null;
}

export default Referrals;