// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Centering the error box and applying styling */
.cancel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  
  .error-box {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .error-box h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .error-box h2 {
    font-size: 18px;
    margin-bottom: 20px;
    color: #e74c3c;
  }
  
  .go-home-btn {
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .go-home-btn:hover {
    background-color: #2980b9;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Decline.css"],"names":[],"mappings":"AAAA,iDAAiD;AACjD;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,2CAA2C;IAC3C,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* Centering the error box and applying styling */\r\n.cancel-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100vh;\r\n    background-color: #f4f4f4;\r\n  }\r\n  \r\n  .error-box {\r\n    background-color: #fff;\r\n    padding: 30px;\r\n    border-radius: 8px;\r\n    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);\r\n    text-align: center;\r\n    max-width: 400px;\r\n    width: 100%;\r\n  }\r\n  \r\n  .error-box h1 {\r\n    font-size: 24px;\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .error-box h2 {\r\n    font-size: 18px;\r\n    margin-bottom: 20px;\r\n    color: #e74c3c;\r\n  }\r\n  \r\n  .go-home-btn {\r\n    padding: 10px 20px;\r\n    background-color: #3498db;\r\n    color: white;\r\n    font-size: 16px;\r\n    border: none;\r\n    border-radius: 5px;\r\n    cursor: pointer;\r\n    transition: background-color 0.3s;\r\n  }\r\n  \r\n  .go-home-btn:hover {\r\n    background-color: #2980b9;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
