// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-links {
    margin-top: 10px;
    text-align: center;
}

.footer-links span {
    cursor: pointer;
    color: #007bff;
    margin-right: 15px;
    text-decoration: underline;
}

.footer-links span:hover {
    color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/components/Policies.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,kBAAkB;IAClB,0BAA0B;AAC9B;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".footer-links {\r\n    margin-top: 10px;\r\n    text-align: center;\r\n}\r\n\r\n.footer-links span {\r\n    cursor: pointer;\r\n    color: #007bff;\r\n    margin-right: 15px;\r\n    text-decoration: underline;\r\n}\r\n\r\n.footer-links span:hover {\r\n    color: #0056b3;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
