import axios from 'axios';
import { useEffect, useState } from 'react';
import './TermsOfService.css';

function TermsOfService() {
    const [policy, setPolicy] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const formatPolicy = (policyText) => {
        // Split the text into sections using numbered headings (e.g., "1.", "2.")
        return policyText.split(/\n(?=\d\.)/).map((section) => section.trim());
    };

    useEffect(() => {
        const fetchTermsOfService = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/terms-of-service`);
                const formattedPolicy = formatPolicy(response.data.termsOfService);
                setPolicy(formattedPolicy);
            } catch (err) {
                console.error('Error fetching terms of service:', err);
                setError('Failed to load terms of service. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchTermsOfService();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <h1>Terms of Service</h1>
            {policy.map((section, index) => (
                <div key={index} className="step-container">
                    <h2>{section.split('\n')[0]}</h2>
                    <p>{section.split('\n').slice(1).join(' ')}</p>
                </div>
            ))}
        </div>
    );
}

export default TermsOfService;
