// client/src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from "../assets/ByteHold.png";

function Navbar() {
    return (
      <header className="navbar">
        <div className="navbar-title">
          <Link to="/" className="navbar-link">
            <img
              src={logo} // Use the imported local image
              alt="Logo"
              className="navbar-logo"
            />
            <span>ByteHold</span>
          </Link>
        </div>
        <nav className="navbar-links">
          <Link to="/">Home</Link>
          <Link to="/subscriptions">Subscriptions</Link>
          <Link to="/help">Help</Link>
          <a href= "https://discord.gg/hA4nUvHvr9" target="_blank" rel="noopener noreferrer">Discord</a>
        </nav>
      </header>
    );
  }
  
  export default Navbar;