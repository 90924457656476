// client/src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar'; // Import Navbar
import Home from './components/Home';
import Subscriptions from './components/Subscriptions';
import Checkout from './components/Checkout';
import Success from './components/Success';
import Help from './components/Help';
import Referrals from './components/Referrals';
import Session from './components/Session';
import Decline from './components/Decline';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import RefundPolicy from './components/RefundPolicy';

function App() {
  return (
    <Router>
      <Navbar /> {/* Navbar is now visible on all pages */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/success" element={<Success />} />
        <Route path="/help" element={<Help />} />
        <Route path="/referral/:id" element={<Referrals />} />
        <Route path="/declined/:error" element={<Decline />} />
        <Route path="/retrieve-session/:session/:plan/:referralId" element={<Session />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-of-service' element={<TermsOfService />} />
        <Route path='/refund-policy' element={<RefundPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;