// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* General container styling for better readability */
.step-container {
    margin: 20px 0;
    margin-left: 25px;
    margin-bottom: 50px;
}

/* Styling for step numbers (e.g., 1., 2., etc.) */
.step-container h1 {
    font-size: 2rem; /* Larger font size for step headings */
    font-weight: bold; /* Make it stand out */
    color: #333; /* Dark gray for contrast */
    margin-bottom: 10px;
}

/* Styling for subheadings (info under step numbers) */
.step-container p {
    font-size: 1.2rem; /* Slightly smaller than step headings */
    color: #555; /* Lighter gray for softer appearance */
    line-height: 1.6; /* Better spacing for readability */
    margin-left: 20px; /* Indented to show hierarchy */
}
`, "",{"version":3,"sources":["webpack://./src/components/TermsOfService.css"],"names":[],"mappings":"AAAA,qDAAqD;AACrD;IACI,cAAc;IACd,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA,kDAAkD;AAClD;IACI,eAAe,EAAE,uCAAuC;IACxD,iBAAiB,EAAE,sBAAsB;IACzC,WAAW,EAAE,2BAA2B;IACxC,mBAAmB;AACvB;;AAEA,sDAAsD;AACtD;IACI,iBAAiB,EAAE,wCAAwC;IAC3D,WAAW,EAAE,uCAAuC;IACpD,gBAAgB,EAAE,mCAAmC;IACrD,iBAAiB,EAAE,+BAA+B;AACtD","sourcesContent":["/* General container styling for better readability */\r\n.step-container {\r\n    margin: 20px 0;\r\n    margin-left: 25px;\r\n    margin-bottom: 50px;\r\n}\r\n\r\n/* Styling for step numbers (e.g., 1., 2., etc.) */\r\n.step-container h1 {\r\n    font-size: 2rem; /* Larger font size for step headings */\r\n    font-weight: bold; /* Make it stand out */\r\n    color: #333; /* Dark gray for contrast */\r\n    margin-bottom: 10px;\r\n}\r\n\r\n/* Styling for subheadings (info under step numbers) */\r\n.step-container p {\r\n    font-size: 1.2rem; /* Slightly smaller than step headings */\r\n    color: #555; /* Lighter gray for softer appearance */\r\n    line-height: 1.6; /* Better spacing for readability */\r\n    margin-left: 20px; /* Indented to show hierarchy */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
