// client/src/components/Help.js
import React, { useState } from 'react';
import './Help.css';

function Help() {
  const [openQuestions, setOpenQuestions] = useState([]); // Track multiple open questions

  const toggleQuestion = (index) => {
    setOpenQuestions((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const faqData = [
    {
      question: "What is ByteHold?",
      answer: "ByteHold is an AFK service for Starve.io that allows users to securely and reliably manage their tokens and maintain uptime without active participation."
    },
    {
      question: "How do I subscribe to ByteHold?",
      answer: "You can click on the 'Subscriptions' button in the navigation bar, then select your desired Byte count. After you input your payment information, you will recieve an activation key, with this you can go to our discord and use the '/subscription activate' with your activation key in the Discord server."
    },
    {
      question: "What is a Byte?",
      answer: "A Byte is what we use to hold your token while you're AFK. With 1 Byte, you can have 1 AFK session at a time. With 3 Bytes, you can have 3 AFK sessions at a time, and so on. Upon subscribing, you unlock unlimited usage of your purchased Byte(s) until your subscription expires."
    },
    {
      question: "How does the subscription work?",
      answer: "Subscriptions are billed monthly and provide access to a specified number of simaltaneous AFK sessions. You can upgrade or cancel your subscription at any time."
    },
    {
      question: "Is my information secure?",
      answer: "We do not handle or store any payment or personal information. All payments are processed through Stripe, a trusted third party payment manager. Stripe prioritizes security and ensures all data is managed in a secure environment."
    },
    {
      question: "How do I upgrade my plan?",
      answer: "Currently we do not provide this feature, but you can cancel your current subscription and then subscribe to a new plan. We are working on adding this feature in the future."
    },
    {
      question: "Can I cancel my subscription?",
      answer: "Yes, you can cancel your subscription at any time through the ByteHold Discord — simply execute the /subscription cancel slash command. Your subscription will remain active until the end of your billing period."
    }
  ];

  return (
    <div className="help-container">
      <h1 className="help-title">Frequently Asked Questions</h1>
      <div className="faq-list">
        {faqData.map((faq, index) => (
          <div key={index} className="faq-item">
            <button className="faq-question" onClick={() => toggleQuestion(index)}>
              {faq.question}
              <span className={`arrow ${openQuestions.includes(index) ? 'open' : ''}`}>&#9660;</span>
            </button>
            <div
              className={`faq-answer ${openQuestions.includes(index) ? 'visible' : ''}`}
              style={{ maxHeight: openQuestions.includes(index) ? '200px' : '0' }}
            >
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Help;